import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { axios } from './main';
// import { createDeviceDetector } from "next-vue-device-detector";
// export const device = createDeviceDetector()
// console.log(device);


const judgeClient = () => {
    // let u = navigator.userAgent
    // let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //判断是否是 android终端
    // let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //判断是否是 iOS终端
    // if (isAndroid) {
    //     return 'Android'
    // } else if (isIOS) {
    //     return 'IOS'
    // } else {
    //     return 'PC'
    // }

    let u = navigator.userAgent
    let isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(u)

    if (isMobile) {
        return 'mobile'
    } else {
        return 'PC'
    }
}

// console.log(judgeClient())

// 封装通用的方法，根据设备类型和组件类型动态生成路径
function getPath(componentType, componentName) {
    const devicePrefix = judgeClient() == 'PC' ? 'pc' : 'mobile';
    const baseDir = componentType === 'components' ? 'components' : 'views';

    // 使用动态import导入组件
    return () => import(`./${baseDir}/${devicePrefix}/${componentName}.vue`);
}


const routes = [
    {
        path: '/',
        name: '',
        // redirect: '/home',
        component: getPath('views', 'Home'),
    },
    {
        path: '/home',
        name: '/home',
        component: getPath('views', 'Home'),
        // children: [
        //     {
        //         path: '/fruits/list',
        //         component: getPath('components', 'fruits/List')
        //     }
        // ]
    }
]

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(''),
    routes
})

router.beforeEach(async (to, from, next) => {
    
    next()
    
})



export default router


// {
//     path: '/gm/edit',
//     name: '/gm/edit',
//     component: getPath('views', 'Home'),
//     redirect: '/gm/edit',
//     children: [
//         {
//             path: '/gm/edit',
//             component: getPath('components', 'Gm/GmEdit')
//         }
//     ]
// }