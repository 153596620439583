<template>
    <div style="position: absolute;">
        <!-- <div class="noisy">
        </div> -->
        <slot></slot>
    </div>
     
</template>

<script>

export default {
    data() {
        return {

        }
    },
    props: {
        
    },
    mounted() {

    },
    methods: {

    }
};
</script>
<style scoped>

</style>