<template>
    <a-row class="titleLine">
        <div class="box">
            <div class="name">.&nbsp;{{ name }}</div>
            <div class="line"></div>
        </div>
    </a-row>
</template>

<script>

export default {
    data() {
        return {

        }
    },
    props: {
        name: String
    },
    mounted() {

    },
    methods: {

    }
};
</script>
<style scoped>
.titleLine {
    width: calc(100% - 2px);
}

.box {
    display: flex;
    align-items: center;
    width: 100%;
}

.name {
    font-size: 14px;
    color: rgba(255, 255, 255, .5);
    margin-right: 12px;
    flex-shrink: 0;
}

.line {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, .1);
}
</style>