import { createStore } from 'vuex';

// 假设我们有一个 navs 数组，包含所有导航项的信息
const navs = [
    { name: '网站首页', path: '/home', key: 'home' },
    { name: '魔术欣赏', path: '/enjoy', key: 'enjoy' },
    { name: '纸牌魔术', path: '/magic/poker', key: 'magic/poker', para: 'poker' },
    { name: '硬币魔术', path: '/magic/coin', key: 'magic/coin', para: 'coin' },
    { name: '心灵魔术', path: '/magic/heart', key: 'magic/heart', para: 'heart' },
    { name: '魔术师', path: '/magician', key: 'magician' }
];

export default createStore({
    state() {
        return {
            // 当前选中的导航名
            tabKey: navs[0].key,
            // 所有导航项的信息
            navs: navs,
            type: 'magic/poker',
            userParam: null,
            bCardShow:false
        };
    },
    mutations: {
        // 更新当前选中的导航名
        SET_TAB_KEY(state, newTabKey) {
            state.tabKey = newTabKey;
        },
        SET_TYPE(state, newType) {
            state.type = newType
        },
        setUserParam(state, userParam) {
            state.userParam = userParam;
        },
        SET_BCardShow(state, newBCardShow){
            console.log('触发2')
            state.bCardShow = newBCardShow;
        }
    },
    actions: {
        // 选择一个导航项
        selectTab({ commit }, newTabKey) {
            // 这里可以执行异步逻辑或其他操作
            commit('SET_TAB_KEY', newTabKey);
        },
        changeType({ commit }, newType) {
            commit('SET_TYPE', newType);
        },
        setUserParam({ commit }, userParam) {
            commit('setUserParam', userParam);
        },
        setBCardShow({ commit }, newBCardShow) {
            console.log(newBCardShow)
            // if(newBCardShow){
            //     document.body.style.overflow = 'hidden';
            // }else{
            //     document.body.style.overflow = 'auto';
            // }
            commit('SET_BCardShow', newBCardShow);
        }
    },
    getters: {
        // 获取当前选中的导航项信息
        currentNav(state) {
            return state.navs.find(nav => nav.key === state.tabKey);
        },
        // 获取所有导航项
        allNavs(state) {
            return state.navs;
        },
        type(state) {
            return state.type;
        },
        getUserParam(state) {
            return state.userParam;
        },
        getBCardShow(state){
            return state.bCardShow;
        }
    }
});